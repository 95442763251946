/* eslint-disable @next/next/no-img-element */
import Layout from '../components/Layout';
import '../styles/globals.scss';
import Head from 'next/head';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const canonicalUrl = (`https://more2nurseries.com` + (router.asPath === '/' ? '' : router.asPath)).split('?')[0];

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default MyApp;
