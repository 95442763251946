import layoutStyles from '../styles/Layout.module.scss';

function Layout({ children }) {
  return (
    <div className={layoutStyles.container}>
      <main className={layoutStyles.main}>
        <div className={layoutStyles.maincontainer}>{children}</div>
      </main>
    </div>
  );
}

export default Layout;
